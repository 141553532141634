import $ from 'jquery';
import 'slick-slider';
// import gsap from 'gsap';
import slick from 'slick-slider';

$('.card-slider-full-container').slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: false,
	dots: true,
	infinite: false,
	arrows: true,
	prevArrow: $('.card-left'),
	nextArrow: $('.card-right'),
});

$('.slider-tabs').slick({
	slidesToShow: 5,
	vertical: true,
	asNavFor: '.slide-container',
	focusOnSelect: true,
});

$('.slide-container').slick({
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	fade: true,
	asNavFor: '.slider-tabs',
});

$('.four-column__card__dropdown').on('click', function (e) {
	let dropdown = $(this);
	const isOpen = dropdown.hasClass('drop-down-open');

	if (!isOpen) {
		const { length } = dropdown[0].dataset;
		const optionHeight = 45,
			labelHeight = 56;
		const dropdownHeight = optionHeight * length + labelHeight;
		dropdown.toggleClass('drop-down-open');
		$(dropdown).css({
			borderRadius: '28px',
			maxHeight: dropdownHeight,
		});
	} else {
		dropdown.toggleClass('drop-down-open');
		$(dropdown).css({
			borderRadius: '45px',
			maxHeight: '56px',
		});
	}
});
$('.open-nav').on('click', function () {
	$('.mobile-nav').css('width', '100%');
});
$('.close-nav').on('click', function () {
	$('.mobile-nav').css('width', '0px');
});

$('.additional').hide();
$('.additional-btn').on('click', function () {
	$('.additional').show();
	$('.additional-btn').hide();
});

var x, i, j, l, ll, selElmnt, a, b, c;
/* Look for any elements with the class "custom-select": */
x = document.getElementsByClassName('custom-select');
l = x.length;
for (i = 0; i < l; i++) {
	selElmnt = x[i].getElementsByTagName('select')[0];
	ll = selElmnt.length;
	/* For each element, create a new DIV that will act as the selected item: */
	a = document.createElement('DIV');
	a.setAttribute('class', 'select-selected');
	a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
	x[i].appendChild(a);
	/* For each element, create a new DIV that will contain the option list: */
	b = document.createElement('DIV');
	b.setAttribute('class', 'select-items select-hide');
	for (j = 1; j < ll; j++) {
		/* For each option in the original select element,
    create a new DIV that will act as an option item: */
		c = document.createElement('DIV');
		c.innerHTML = selElmnt.options[j].innerHTML;
		c.addEventListener('click', function (e) {
			/* When an item is clicked, update the original select box,
        and the selected item: */
			var y, i, k, s, h, sl, yl;
			s = this.parentNode.parentNode.getElementsByTagName('select')[0];
			sl = s.length;
			h = this.parentNode.previousSibling;
			for (i = 0; i < sl; i++) {
				if (s.options[i].innerHTML == this.innerHTML) {
					s.selectedIndex = i;
					h.innerHTML = this.innerHTML;
					y = this.parentNode.getElementsByClassName('same-as-selected');
					yl = y.length;
					for (k = 0; k < yl; k++) {
						y[k].removeAttribute('class');
					}
					this.setAttribute('class', 'same-as-selected');
					break;
				}
			}
			h.click();
		});
		b.appendChild(c);
	}
	x[i].appendChild(b);
	a.addEventListener('click', function (e) {
		/* When the select box is clicked, close any other select boxes,
    and open/close the current select box: */
		e.stopPropagation();
		closeAllSelect(this);
		this.nextSibling.classList.toggle('select-hide');
		this.classList.toggle('select-arrow-active');
	});
}

function closeAllSelect(elmnt) {
	/* A function that will close all select boxes in the document,
  except the current select box: */
	let x,
		y,
		i,
		xl,
		yl,
		arrNo = [];
	x = document.getElementsByClassName('select-items');
	y = document.getElementsByClassName('select-selected');
	xl = x.length;
	yl = y.length;
	for (i = 0; i < yl; i++) {
		if (elmnt == y[i]) {
			arrNo.push(i);
		} else {
			y[i].classList.remove('select-arrow-active');
		}
	}
	for (i = 0; i < xl; i++) {
		if (arrNo.indexOf(i)) {
			x[i].classList.add('select-hide');
		}
	}
}

/* If the user clicks anywhere outside the select box,
then close all select boxes: */
document.addEventListener('click', closeAllSelect);

function abbrState(input, to) {
	var states = [
		['Arizona', 'AZ'],
		['Alabama', 'AL'],
		['Alaska', 'AK'],
		['Arkansas', 'AR'],
		['California', 'CA'],
		['Colorado', 'CO'],
		['Connecticut', 'CT'],
		['Delaware', 'DE'],
		['Florida', 'FL'],
		['Georgia', 'GA'],
		['Hawaii', 'HI'],
		['Idaho', 'ID'],
		['Illinois', 'IL'],
		['Indiana', 'IN'],
		['Iowa', 'IA'],
		['Kansas', 'KS'],
		['Kentucky', 'KY'],
		['Louisiana', 'LA'],
		['Maine', 'ME'],
		['Maryland', 'MD'],
		['Massachusetts', 'MA'],
		['Michigan', 'MI'],
		['Minnesota', 'MN'],
		['Mississippi', 'MS'],
		['Missouri', 'MO'],
		['Montana', 'MT'],
		['Nebraska', 'NE'],
		['Nevada', 'NV'],
		['New Hampshire', 'NH'],
		['New Jersey', 'NJ'],
		['New Mexico', 'NM'],
		['New York', 'NY'],
		['North Carolina', 'NC'],
		['North Dakota', 'ND'],
		['Ohio', 'OH'],
		['Oklahoma', 'OK'],
		['Oregon', 'OR'],
		['Pennsylvania', 'PA'],
		['Rhode Island', 'RI'],
		['South Carolina', 'SC'],
		['South Dakota', 'SD'],
		['Tennessee', 'TN'],
		['Texas', 'TX'],
		['Utah', 'UT'],
		['Vermont', 'VT'],
		['Virginia', 'VA'],
		['Washington', 'WA'],
		['West Virginia', 'WV'],
		['Wisconsin', 'WI'],
		['Wyoming', 'WY'],
	];

	if (to == 'abbr') {
		input = input.replace(/\w\S*/g, function (txt) {
			return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		});
		for (i = 0; i < states.length; i++) {
			if (states[i][0] == input) {
				return states[i][1];
			}
		}
	} else if (to == 'name') {
		for (i = 0; i < states.length; i++) {
			if (states[i][1] == input) {
				return states[i][0];
			}
		}
	}
}
$('.select-items div').on('click', function () {
	let text = $(this).text();
	let state = abbrState(text, 'abbr');
	$('.cs-input').val(state);
	$('.custom-select').submit();
});

let stateId = $('.stateSelected').attr('data-id');
let stateName = abbrState(stateId, 'name');

let stateSelectId = $('.state-selected').data('id');

let stateNameFull = abbrState(stateSelectId, 'name');

$('#e-span').textContent = stateNameFull;

let sorry = $('.sorryText');
let resources = $('.resourceBlock');

$('.resources__checked').on('click', function () {
	$(this).toggleClass('resources__checkbox');
	let id = $(this).attr('id');
	let modId = "'." + id + "'";

	if ($(this).hasClass('resources__checkbox')) {
		$(`.${id}`).addClass('hidden');
		$(`.${id}`).fadeOut();
	} else {
		$(`.${id}`).fadeIn();
		$(`.${id}`).removeClass('hidden');
	}
	if ($('.hidden').length == resources.length) {
		sorry.fadeIn();
	} else {
		sorry.fadeOut();
	}
});

$('.resources__filTop').on('click', function () {
	$(this).toggleClass('resources__filTop--clicked');
	$('.resources__filBody').toggleClass('resources__filBody--clicked');
});
